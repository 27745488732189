<template>
  <div>
    <ModuleComponent
      ref="mc"
      name="lands"
      @beforeSaveItem="beforeSaveItem"
      @changed="item = $event"
      :module-data="moduleData"
      :hide-filters="hideFilters"
      :no-bread-crumb="noBreadCrumb"
    >
      <template v-slot:tableFilters="{ filters }">
        <b-container fluid>
          <b-row>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.BUYERS.D')">
                <v-select2
                  v-model="filters.buyerId.value"
                  endPoint="accounts"
                  :placeholder="t('GLOBAL.BUYERS.D')"
                  :filters="{
                    accountTypeId: { value: Constants.AccountTypes.Buyer }
                  }"
                  sort="name"
                ></v-select2>
              </b-form-group>
            </b-col>

            <b-col cols="3" sm="6" md="3">
              <b-form-group :label="t('GLOBAL.FARMER')">
                <v-select2
                  v-model="filters.farmerId.value"
                  endPoint="accounts"
                  :placeholder="t('GLOBAL.FARMER')"
                  :filters="{
                    accountTypeId: { value: Constants.AccountTypes.Farmer }
                  }"
                  sort="name"
                ></v-select2>
              </b-form-group>
            </b-col>

            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.PRODUCT')">
                <v-select2
                  v-model="filter_productId"
                  endPoint="products"
                  sort="name"
                  :placeholder="t('GLOBAL.PRODUCT')"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.PRODUCTKIND')">
                <v-select2
                  v-model="filters.productKindId.value"
                  endPoint="productKinds"
                  sort="name"
                  :placeholder="t('GLOBAL.PRODUCTKIND')"
                  :filters="{
                    productId: {
                      value: filter_productId
                    }
                  }"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="6" md="3">
              <b-form-group :label="t('GLOBAL.STARTDATE')">
                <BDatePicker
                  :id="'startDate'"
                  v-model="filters.startDate.value"
                  @input="
                    filters.startDate.value = $event;
                    filters.endDate.value = $event;
                  "
                  hide-header
                  show-decade-nav
                  :placeholder="t('GLOBAL.STARTDATE')"
                >
                </BDatePicker>
              </b-form-group>
            </b-col>

            <b-col cols="3" sm="6" md="3">
              <b-form-group :label="t('GLOBAL.ENDDATE')">
                <BDatePicker
                  :id="'endDate'"
                  v-model="filters.endDate.value"
                  @input="filters.endDate.value = $event"
                  hide-header
                  show-decade-nav
                  :placeholder="t('GLOBAL.ENDDATE')"
                >
                </BDatePicker>
              </b-form-group>
            </b-col>

            <b-col cols="3" sm="6" md="3">
              <b-form-group :label="t('GLOBAL.STATUS')" class="mb-3">
                <vue-select
                  v-model="filters.status.value"
                  label="text"
                  :options="[
                    { text: t('GLOBAL.ONLINE'), value: 1 },
                    { text: t('GLOBAL.OFFLINE'), value: 0 }
                  ]"
                  :multiple="false"
                  :placeholder="t('GLOBAL.PLEASESELECT')"
                >
                </vue-select>
              </b-form-group>
            </b-col>

            <b-col cols="3" sm="6" md="3">
              <b-form-group :label="t('GLOBAL.SUBSTATUS')" class="mb-3">
                <vue-select
                  v-model="filters.substatus.value"
                  label="text"
                  :options="[
                    { text: t('GLOBAL.ONLINE'), value: 1 },
                    { text: t('GLOBAL.OFFLINE'), value: 0 }
                  ]"
                  :multiple="false"
                  :placeholder="t('GLOBAL.PLEASESELECT')"
                >
                </vue-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template v-slot:tableRowActions="{ rowItem }"></template>

      <template v-slot:form="{ item }">
        <b-tabs content-class="mt-3" class="mt-3 form-group-mb-0">
          <b-tab :title="t('GLOBAL.REQUEST')" active>
            <div class="mt-3 form-group-mb-0">
              <b-row>
                <b-col cols="6" sm="6" md="6">
                  <b-form-group :label="t('GLOBAL.BUYERS.D')">
                    <v-select2
                      v-model="item.buyerId"
                      :text="
                        item.buyer && item.buyer.account
                          ? item.buyer.account.name
                          : ''
                      "
                      endPoint="accounts"
                      :placeholder="t('GLOBAL.BUYERS.D')"
                      :filters="{
                        accountTypeId: { value: Constants.AccountTypes.Buyer }
                      }"
                      sort="name"
                    ></v-select2>
                  </b-form-group>
                </b-col>
                <b-col cols="6" sm="6" md="6">
                  <b-form-group :label="t('GLOBAL.DATE')">
                    <BDatePicker
                      :id="'startDate'"
                      v-model="item.date"
                      @input="item.date = $event"
                      hide-header
                      show-decade-nav
                      :placeholder="t('GLOBAL.DATE')"
                    >
                    </BDatePicker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" sm="6" md="6">
                  <b-form-group :label="t('GLOBAL.PRODUCT')">
                    <v-select2
                      v-model="productId"
                      :text="
                        item.productKind && item.productKind.product
                          ? item.productKind.product.name
                          : ''
                      "
                      endPoint="products"
                      :placeholder="t('GLOBAL.PRODUCT')"
                      sort="name"
                      @input="item.productKindId = null"
                    ></v-select2>
                  </b-form-group>
                </b-col>
                <b-col cols="6" sm="6" md="6">
                  <b-form-group :label="t('GLOBAL.PRODUCTKIND')">
                    <v-select2
                      v-model="item.productKindId"
                      :text="
                        item.productKindId && item.productKindId.name
                          ? item.productKind.name
                          : ''
                      "
                      endPoint="productKinds"
                      :placeholder="t('GLOBAL.PRODUCTKIND')"
                      sort="name"
                      :filters="{ productId: { value: productId } }"
                    ></v-select2>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" sm="6" md="6">
                  <b-form-group :label="t('GLOBAL.AMOUNT')" v-if="item.id > 0">
                    <span>{{ item.amount }}</span>
                  </b-form-group>
                  <b-form-group :label="t('GLOBAL.AMOUNT')" v-if="item.id == 0">
                    <b-input-group>
                      <decimal-input v-model="item.amount" />
                      <b-input-group-append>
                        <b-input-group-text>KG</b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="3" sm="3" md="3">
                  <b-form-group :label="t('GLOBAL.STATUS')">
                    <span v-if="item.status">{{ item.status.name }}</span>
                  </b-form-group>
                </b-col>
                <b-col cols="3" sm="3" md="3">
                  <b-form-group :label="t('GLOBAL.SUBSTATUS')">
                    <span v-if="item.subStatus">{{ item.subStatus.name }}</span>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab :title="t('GLOBAL.DETAILS')" v-if="item.id > 0">
            <b-row>
              <b-col cols="7" sm="7" md="7" v-if="item.details.length > 0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">{{ t("GLOBAL.FARMER") }}</th>
                        <th class="text-center">{{ t("GLOBAL.DATE") }}</th>
                        <th class="text-center">{{ t("GLOBAL.AMOUNT") }}</th>
                        <th class="text-center">
                          {{ t("GLOBAL.REQUESTEDAMOUNT") }}
                        </th>
                        <th class="text-center">
                          {{ t("GLOBAL.LOGISTICCOMPANIES.D") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(p, index) in item.details" :key="index">
                        <td class="text-center">
                          {{ p.harvest.cropLand.land.farmer.account.name }}
                        </td>
                        <td class="text-center">
                          {{ p.harvest.startDate }}<br />{{ p.harvest.endDate }}
                        </td>
                        <td class="text-center">{{ p.harvest.amount }}</td>
                        <td class="text-center">{{ p.amount }}</td>
                        <td class="text-center">
                          {{ p.logisticCompany.account.name }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            :title="t('GLOBAL.ACTIVITYHISTORY')"
            @click="HistoryTabClicked"
            v-if="item.id > 0"
          >
            <b-row>
              <b-button
                @click="createNewActivity"
                class="mr-1"
                size="sm"
                v-if="newActivity == null"
                >{{
                  t("GLOBAL.NEW", {
                    name: t("GLOBAL.ACTIVITY")
                  })
                }}
              </b-button>
              <b-button
                @click="cancelNewActivity"
                class="mr-1"
                size="sm"
                v-if="newActivity != null"
                >{{ t("GLOBAL.CANCEL") }}
              </b-button>
              <b-col cols="4" sm="4" md="4" v-if="newActivity != null">
                <b-row>
                  <b-col cols="12" sm="12" md="12">
                    <b-form-group :label="t('GLOBAL.ACTIVITY')">
                      <v-select2
                        v-model="newActivity.activityId"
                        rest
                        method="GET"
                        textField="name"
                        valueField="id"
                        :endPoint="
                          'harvestRequests/' + item.id + '/getnextactivities'
                        "
                        :placeholder="t('GLOBAL.ACTIVITY')"
                      ></v-select2>
                    </b-form-group>
                    <b-form-group :label="t('GLOBAL.NOTE')">
                      <b-form-input v-model="newActivity.note"></b-form-input>
                    </b-form-group>
                    <b-button @click="addNewActivity" class="mr-1" size="sm"
                      >{{ t("GLOBAL.SAVE") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7" sm="7" md="7" v-if="newActivity == null">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">{{ t("GLOBAL.DATE") }}</th>
                        <th class="text-center">{{ t("GLOBAL.ACTIVITY") }}</th>
                        <th class="text-center">{{ t("GLOBAL.STATUS") }}</th>
                        <th class="text-center">{{ t("GLOBAL.SUBSTATUS") }}</th>
                        <th>{{ t("GLOBAL.NOTE") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(p, index) in activities" :key="index">
                        <td class="text-center">{{ p.date }}</td>
                        <td class="text-center">{{ p.activity.name }}</td>
                        <td class="text-center">
                          {{ p.status ? p.status.name : "" }}
                        </td>
                        <td class="text-center">
                          {{ p.subStatus ? p.subStatus.name : "" }}
                        </td>
                        <td class="text-center">{{ p.note }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </template>
    </ModuleComponent>
  </div>
</template>
<script>
// http://core.datadns.net:5000/swagger/index.html#model-Land

export default {
  name: "harvestRequests",
  components: {},
  methods: {
    beforeSaveItem(item) {
      item.status = item.subStatus = null;
      item.activityHistory = null;
      item.details = null;
    },
    createNewActivity() {
      this.newActivity = {
        activityId: null,
        note: null
      };
    },
    cancelNewActivity() {
      this.newActivity = null;
    },
    addNewActivity() {
      let that = this;
      this.apiSend("post", "harvestRequests/" + this.item.id + "/AddActivity", {
        activityId: this.newActivity.activityId,
        note: this.newActivity.note
      }).then(() => {
        this.$refs.mc.loadEditItem(this.item.id);
        that.cancelNewActivity();
        that.loadActivityHistory();
      });
    },
    HistoryTabClicked() {
      this.loadActivityHistory();
    },
    loadActivityHistory() {
      this.apiSendGraph(
        "harvestRequestActivityHistories",
        `
                      id,
                      activity{name},
                      status{name},
                      subStatus{name},
                      date,
                      note
                     `,
        "id DESC",
        {
          objectId: { value: this.item.id }
        }
      ).then(data => {
        this.activities = data["data"];
      });
    }
  },
  data() {
    return {
      filter_productId: 0,
      productId: 0,
      activities: null,
      newActivity: null,
      newDetail: null,
      item: {},
      moduleData: {
        name: "harvestRequests",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: true,
          graphqlQuery: `
                            id,
                            buyer{account{name}},
                            amount,
                            productKind{
                                name,
                                product{name}
                            },
                            date,
                            status{name},
                            subStatus{name}
                        `,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.BUYERS.D"), value: "buyer.account.name" },
            { text: this.t("GLOBAL.AMOUNT"), value: "amount" },
            {
              text: this.t("GLOBAL.PRODUCT.D"),
              value: "productKind.product.name"
            },
            {
              text: this.t("GLOBAL.PRODUCTKIND.D"),
              value: "productKind.name"
            },
            { text: this.t("GLOBAL.DATE"), value: "date" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "status.name",
              width: "60",
              align: "center"
            },
            {
              text: this.t("GLOBAL.SUBSTATUS"),
              value: "subStatus.name",
              width: "60",
              align: "center"
            }
          ],
          customColumns: [],
          filters: {
            buyerId: {
              type: "number",
              value: null
            },
            farmerId: {
              // field:"",
              type: "number",
              value: null
            },
            productKindId: {
              type: "number",
              value: null
            },
            startDate: {
              type: "date",
              value: null
            },
            endDate: {
              type: "date",
              value: null
            },
            status: {
              type: "number",
              value: null
            },
            substatus: {
              type: "number",
              value: null
            }
          }
        },
        form: {
          fullScreen: true
        }
      }
    };
  },
  watch: {
    item(item) {
      this.productId =
        item.productKind &&
        item.productKind.product &&
        item.productKind.product.id !== "undefined"
          ? item.productKind.product.id
          : this.productId;
    }
  }
};
</script>
